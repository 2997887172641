<template>
  <v-dialog
    v-model="dialogCompras"
    @click:outside="$emit('update:dialogCompras', false)"
    @keydown.esc="$emit('update:dialogCompras', false)"
    width="1500px"
    scrollable
  >
    <v-card style="height: 800px">
      <PageToolbar
        :title="$tc('global.compra', 2)"
        icon="mdi-cart-outline"
        dark
      />

      <v-card :loading="loading" class="mb-0" style="height: 100%">
        <v-card-title class="px-md-6 subtitle-1 tocs_gray_1">
          <v-text-field
            style="max-width: 400px"
            v-model="search"
            append-icon="mdi-magnify"
            :label="$tc('global.buscar')"
            clearable
            solo
            flat
            outlined
            dense
            hide-details
          ></v-text-field>
          <v-select
            v-model="statusFilter"
            :items="status_lista"
            label="Status"
            multiple
            hide-details
            dense
            solo
            flat
            style="max-width: 450px"
            class="ml-0 ml-sm-3 mt-4 mt-sm-0"
          >
            <template v-slot:item="{ item }">
              <div class="d-flex align-center justify-start">
                <v-sheet
                  width="10px"
                  height="10px"
                  :color="item.color"
                  style="border-radius: 3px"
                  class="mr-2"
                >
                </v-sheet>
                {{ item.text }}
              </div>
            </template>

            <template v-slot:selection="{ item, index }">
              <div class="d-flex align-center justify-start">
                <v-sheet
                  width="10px"
                  height="10px"
                  :color="item.color"
                  style="border-radius: 3px"
                  class="mr-2"
                >
                </v-sheet>
                <span class="text-caption">{{ item.text }}</span>
              </div>
              <v-divider
                v-if="index < statusFilter.length - 1"
                class="mx-2 mt-8 mt-sm-0"
                vertical
              ></v-divider>
            </template>
          </v-select>

          <v-spacer class="d-none d-sm-flex"></v-spacer>

          <ComprasTiposDespesas v-if="PDespesaVisualizar" />

          <v-btn
            color="button_1"
            class="white--text ml-4"
            v-if="PCompraAdicionar"
            @click="dialogTipoCompras = true"
          >
            {{ $tc("global.add") }}
            <v-icon right>mdi-plus</v-icon>
          </v-btn>

          <DialogAddCompra
            v-if="dialogAddCompra"
            :item="compra"
            :edit="edit"
            :dialogAddCompra.sync="dialogAddCompra"
            :tipo_compra="tipo_compra"
            @fetch-compras="getCompras"
          />

          <DialogTiposCompras
            v-if="dialogTipoCompras"
            :dialogTipoCompras.sync="dialogTipoCompras"
            @compra-local="openCompraLocal"
            @compra-local-fatura="openCompraLocalFatura"
            @compra-importacao="openCompraImportacao"
          />
        </v-card-title>

        <v-divider></v-divider>
        <v-card-text class="py-6">
          <v-data-table
            :headers="headers"
            :items="compras"
            :search="search"
            :items-per-page="15"
            class="data-tables"
            :class="{ 'data-tables__row-click': PCompraVisualizar }"
            @click:row="openCompra"
          >
            <template v-slot:item.data_compra="{ item }">
              {{ item.data_compra | dateFormat("dd/MM/yyyy") }}
            </template>

            <template v-slot:item.sub_total="{ item }">
              {{ item.moeda_sigla }}
              <span v-if="item.moeda_sigla === 'G$'">
                {{ item.sub_total | guarani }}
              </span>
              <span v-else>
                {{ item.sub_total | currency }}
              </span>
            </template>

            <template v-slot:item.cambio="{ item }">
              {{ item.moeda_base_sigla }}
              <span v-if="item.moeda_base_sigla === 'G$'">
                {{ item.cambio | guarani }}
              </span>
              <span v-else>
                {{ item.cambio | currency }}
              </span>
            </template>

            <template v-slot:item.desconto="{ item }">
              {{ item.moeda_sigla }}
              <span v-if="item.moeda_sigla === 'G$'">
                {{ item.desconto | guarani }}
              </span>
              <span v-else>
                {{ item.desconto | currency }}
              </span>
            </template>

            <template v-slot:item.total="{ item }">
              {{ item.moeda_sigla }}
              <span v-if="item.moeda_sigla === 'G$'">
                {{ item.total | guarani }}
              </span>
              <span v-else>
                {{ item.total | currency }}
              </span>
            </template>

            <template v-slot:item.status="{ item }">
              <div
                v-if="item.status === -1"
                class="d-flex align-center justify-start"
              >
                <v-sheet
                  width="10px"
                  height="10px"
                  color="red lighten-1"
                  style="border-radius: 3px"
                  class="mr-2"
                >
                </v-sheet>
                {{ $tc("global.cancelada") }}
              </div>

              <div
                v-if="item.status === 0"
                class="d-flex align-center justify-start"
              >
                <v-sheet
                  width="10px"
                  height="10px"
                  color="grey lighten-1"
                  style="border-radius: 3px"
                  class="mr-2"
                >
                </v-sheet>
                {{ $tc("global.rascunho") }}
              </div>

              <div
                v-if="item.status === 1"
                class="d-flex align-center justify-start"
              >
                <v-sheet
                  width="10px"
                  height="10px"
                  color="yellow lighten-1"
                  style="border-radius: 3px"
                  class="mr-2"
                >
                </v-sheet>
                {{ $tc("global.pendentes") }}
              </div>
              <div
                v-if="item.status === 2"
                class="d-flex align-center justify-start"
              >
                <v-sheet
                  width="10px"
                  height="10px"
                  color="green lighten-1"
                  style="border-radius: 3px"
                  class="mr-2"
                >
                </v-sheet>

                {{ $tc("global.finalizado") }}
              </div>
            </template>

            <template v-slot:item.tipo_compra="{ item }">
              <span v-if="item.tipo_compra === 1">
                {{ $tc("global.localSemFatura") }}
              </span>
              <span v-if="item.tipo_compra === 2">
                {{ $tc("global.localFatura") }}
              </span>
              <span v-if="item.tipo_compra === 3">
                {{ $tc("global.importacao") }}
              </span>
            </template>
            <template v-slot:item.actions="{ item }">
              <span v-if="item.status === 0">
                <v-btn
                  v-if="PCompraDeletar"
                  icon
                  small
                  @click.stop="descartarRascunho(item)"
                  ><v-icon color="red">mdi-delete</v-icon></v-btn
                >
              </span>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import { fetchAllCompras, descartaCompra } from "@/api/compras/compras.js";
import { mapState, mapGetters } from "vuex";

export default {
  name: "Compras",

  data() {
    return {
      search: "",
      loading: false,
      compras: [],
      compra: {},
      dialogAddCompra: false,
      dialogTipoCompras: false,
      tipo_compra: null,
      cambio_moeda: null,
      statusFilter: [0, 1],
    };
  },
  components: {
    ComprasTiposDespesas: () =>
      import("@/views/dashboard/compras/compras_plano_contas/ComprasTiposDespesas.vue"),
    DialogAddCompra: () => import("@/views/dashboard/compras/components/DialogAddCompra.vue"),

    DialogTiposCompras: () => import("@/views/dashboard/compras/components/DialogTiposCompras.vue"),
  },

  computed: {
    ...mapGetters("Componentes", ["getAccess"]),

    PCompraVisualizar() {
      return this.getAccess("Compras", "visualizar");
    },
    PCompraAdicionar() {
      return this.getAccess("Compras", "adicionar");
    },
    PCompraDeletar() {
      return this.getAccess("Compras", "deletar");
    },
    PDespesaVisualizar() {
      return this.getAccess("PlanoDespesas", "visualizar");
    },

    ...mapState("Usuario", {
      moeda_empresa: (state) => state.empresa.moeda_empresa,
    }),
    ...mapState("Moedas", {
      moedas: (state) => state.moedas,
    }),
    headers() {
      return [
        {
          text: this.$tc("global.fornecedor"),
          value: "fornecedor_nome",
        },
        {
          text: this.$tc("global.documento"),
          value: "documento",
        },
        {
          text: this.$tc("global.tipo"),
          value: "tipo_compra",
        },
        {
          text: this.$tc("global.data") + " " + this.$tc("global.compra"),
          value: "data_compra",
        },
        {
          text: this.$tc("global.cambio"),
          value: "cambio",
        },
        {
          text: "Subtotal",
          value: "sub_total",
        },
        {
          text: this.$tc("global.desconto"),
          value: "desconto",
        },
        {
          text: this.$tc("global.total"),
          value: "total",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "",
          width: "90px",
          value: "actions",
        },
      ];
    },

    url() {
      let queryString = "";
      for (let key in this.$route.query) {
        queryString += `&${key}=${this.$route.query[key]}`;
      }

      if (this.statusFilter.length) {
        queryString += `&status_filter=${this.statusFilter.join()}`;
      }
      return `?${queryString}`;
    },

    status_lista() {
      return [
        {
          text: this.$tc("global.cancelada"),
          value: -1,
          color: "red lighten-1",
        },
        {
          text: this.$tc("global.rascunho"),
          value: 0,
          color: "grey lighten-1",
        },
        {
          text: this.$tc("global.pendentes"),
          value: 1,
          color: "yellow lighten-1",
        },
        {
          text: this.$tc("global.finalizado"),
          value: 2,
          color: "green lighten-1",
        },
      ];
    },
  },

  props: {
    dialogCompras: {
      type: Boolean,
      required: true,
    },
  },

  watch: {
    url: {
      handler() {
        this.getCompras();
      },
      immediate: true,
    },
  },

  methods: {
    openCompraLocal() {
      this.tipo_compra = 1;
      this.dialogAddCompra = true;
      this.edit = false;
    },
    openCompraLocalFatura() {
      this.tipo_compra = 2;
      this.dialogAddCompra = true;
      this.edit = false;
    },

    openCompraImportacao() {
      this.tipo_compra = 3;
      this.dialogAddCompra = true;
      this.edit = false;
    },

    openCompra(item) {
      if (this.PCompraVisualizar) {
        this.edit = true;
        this.compra = { ...item };
        this.dialogAddCompra = true;
        if (item.tipo_compra === 2) {
          this.com_fatura = true;
        } 
      }
    },

    descartarRascunho(item) {
      this.loading = true;
      if (item.status === 0) {
        descartaCompra(item.id)
          .then((response) => {
            if (response.status === 200) {
              this.getCompras();
              this.loading = false;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },

    async getCompras() {
      const response = await fetchAllCompras(this.url);
      this.compras = response;
    },
  },

  mounted() {
    this.getCompras();
  },
};
</script>

<style lang="scss" scoped></style>
